import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Col } from "reactstrap"
import styled from "styled-components"
import { Card, CardBody as StrapCardBody } from "reactstrap"
import Button from "../components/buttons/btn"
import Link from "../components/link"
import SEO from "../components/seo"
import { Paragraph as GrommetParagraph } from "grommet"
import TitleBanner from "../components/titleBanner/TitleBanner"
import BasicInfo from "../components/product-detail/basicInfo/BasicInfo"
import AcademyAdvantagesInfo from "../components/product-detail/academyAdvantagesInfo/AcademyAdvantagesInfo"
import ItemDescription from "../components/product-detail/itemDescription/ItemDescription"
import MoreItems from "../components/product-detail/moreItems/MoreItems"
import ContactDetails from "../components/product-detail/Contact/ContactDetails"
import * as queryString from "query-string"
import PaymentModal from "../components/paymentModal/payment-modal"
import "./course-post.scss"
import Testimonials from "../components/home/testimonials/Testimonials"

const CoursePost = ({ data, location }) => {
  const [register, setRegister] = useState(false)
  const [modal, setModal] = useState(false)
  let { checkout } = queryString.parse(location.search)
  const [paymentModal, setPaymentModal] = useState(false)

  useEffect(() => {
    if (checkout) {
      if (checkout === "success" || checkout === "fail") {
        setPaymentModal(true)
      }
    }
  }, [checkout])

  const [show, setShow] = useState()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setShow(window.scrollY > 400)
    })
  }, [])

  const onPaymentModalClose = () => {
    setPaymentModal(false)
    window.history.pushState(
      null,
      "",
      window.location.pathname
    )
    window.localStorage.removeItem("Email")
  }

  const {
    id,
    title,
    lessons,
    overview,
    metaDescription,
    coverPhoto,
    product,
    duration,
    slug,
    requirements,
    selfCheckout,
    courseContent,
    skillsFramework,
    courseRuns,
    courseReference,
    trainingMode,
    skillsCode,
    skillsCodeName,
    funding,
    assessment,
    projectImplementation,
  } = data.contentfulTraining

  const renderATOCourse = () => {
    useEffect(() => { }, [product])

    return (
      <>
        <SEO
          title={title}
          description={metaDescription || overview}
        />

        <TitleBanner
          id={id}
          scroll={show}
          title={title}
          description={metaDescription || overview}
          withoutFilter
          slug={slug}
          netFee={product?.netFee || product?.price}
          coverPhoto={coverPhoto?.fluid}
          selfCheckout={selfCheckout}
          courseRuns={courseRuns}
        />
        <section className="course_details">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-md-12 col-lg-7">
                <div className="left-side">
                  <ItemDescription
                    courseContent={courseContent}
                    lessons={lessons}
                    funding={funding}
                    assessment={assessment}
                    projectImplementation={projectImplementation}
                    requirements={requirements}
                    skillsFramework={skillsFramework}
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-12 col-lg-5">
                <div className="right-side">
                  <ContactDetails />
                  <BasicInfo
                    duration={duration}
                    netFee={product?.netFee}
                    courseReference={courseReference}
                    trainingMode={trainingMode}
                    price={product?.price}
                    slug={slug}
                    skillsCode={skillsCode}
                    skillsCodeName={skillsCodeName}
                  />
                </div>
              </div>
              <div className="col-xl-12">
                <hr className="v_line" />
                <MoreItems />
                <AcademyAdvantagesInfo />
              </div>
            </div>
          </div>
        </section>

        <div className="review">
          <Testimonials />
        </div>

        <PaymentModal
          title={title}
          checkoutType={checkout}
          isOpen={paymentModal}
          toggle={onPaymentModalClose}
          selfCheckout={selfCheckout}
        />
      </>
    )
  }

  return <>{renderATOCourse()}</>
}

export default CoursePost

export const query = graphql`
  query singleTrainingQuery($slug: String!) {
    contentfulTraining(slug: { eq: $slug }) {
      id
      title
      overview
      metaDescription
      lessons
      slug
      duration
      category
      coverPhoto {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      product {
        name
        price
        netFee
        currency
        apiID
        apiIdTest
      }
      courseContent {
        raw
      }
      courseReference
      courseObjectives {
        raw
      }
      courseRuns
      subsidyPeriod
      trainingMode
      entryRequirements {
        raw
      }
      requirements
      skillsFramework
      subsidyPeriod
      trainingMode
      selfCheckout
      skillsCode
      skillsCodeName
      funding
      assessment
      projectImplementation
    }
  }
`
