import styled from "styled-components"

export const CuricullamContainer = styled.div`
  margin-bottom: 40px;
`
export const Title = styled.h2`
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 991px) {
    font-size: 28px;
    line-height: 36px;
    max-width: 274px;
  }
`

export const List = styled.li`
  width: 48%;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  p{
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #555555;
    position: relative;
    padding-left: 0px;
    max-width: 460px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media(max-width:991px){
    width: 100%;
    order:2;
    justify-content:center;
  }
}
`

export const ListMap = styled.li`
  width: 48%;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  p{
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #555555;
    position: relative;
    padding-left: 0px;
    max-width: 460px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  @media(max-width:991px){
    width: 100%;
    order:1;
    justify-content:center;
  }
}
`

export const CurriculumListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`

export const ContactContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  margin-bottom: 25px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
    flex-direction: column;
    margin-bottom: 65px;
  }
`
export const AddressInfoContainer = styled.div`
  margin-left: 90px;
  @media (max-width: 991px) {
    margin-right: auto;
    width: 45%;
    margin-left: auto;
    width: auto;
  }
`

export const AddressInfoCover = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgb(153 153 153 / 13%);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 33px;
`
export const ContactIconCover = styled.div`
  background: rgba(0, 53, 145, 0.14);
  border-radius: 6px;
  width: 51px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`
export const InfoContent = styled.div`
  h3 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #555555;
    margin-bottom: 0;
  }

  h4 {
    max-width: 165px;
    font-size: 16px;
  }
}`

export const MapIframe = styled.iframe`
  border: 0;
  width: 100%;
  border-radius: 10px;
`
