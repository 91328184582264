import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import "./paymentmodal.scss"
import Lottie from "lottie-react"
import paymentSuccess from "../../data/payment-success.json"
import paymentFailed from "../../data/payment-failed.json"
import { ImageWrap } from "./styles"
import { navigate } from "gatsby"

function PaymentModal(props) {
  const { checkoutType, selfCheckout } = props
  return (
    <>
      {checkoutType === "success" && (
        <PaymentSuccessModal {...props} />
      )}
      {checkoutType === "fail" && (
        <PaymentFailedModal {...props} />
      )}
    </>
  )
}

export default PaymentModal

const PaymentSuccessModal = (props) => {
  const { isOpen, toggle, title } = props
  const email = window.localStorage.getItem("Email")
  return (
    <Modal
      className="PaymentModal"
      centered
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className="success">
        Payment Successful
      </ModalHeader>
      <ModalBody>
        <ImageWrap>
          <Lottie animationData={paymentSuccess} loop={1} />
        </ImageWrap>
        <p>
          {" "}
          Your registration for
          <b> {title} </b>
          was successful
        </p>
        <p>
          {" "}
          Further information and instrustions on how to
          access this course has been sent to your
          registered email
          <a href="mailto:"> {email} </a>.
        </p>
        <span>
          For support, contact{" "}
          <a href="mailto:training@aia.edu.sg">
            training@aia.edu.sg
          </a>
          .{" "}
        </span>
      </ModalBody>
    </Modal>
  )
}

const PaymentFailedModal = (props) => {
  const { isOpen, toggle } = props
  return (
    <Modal
      className="PaymentModal"
      centered
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className="fail">
        Payment Declined
      </ModalHeader>
      <ModalBody>
        <ImageWrap>
          <Lottie animationData={paymentFailed} loop={1} />
        </ImageWrap>
        <p>
          {" "}
          Your payment method was declined. Please contact
          your payment provider or change your payment
          method and try again
        </p>
        <span>
          For support, contact{" "}
          <a href="mailto:training@aia.edu.sg">
            training@aia.edu.sg
          </a>
          .{" "}
        </span>
      </ModalBody>
    </Modal>
  )
}
