import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box } from "grommet"
import { Title, MapIframe } from "./styles"

const ContactDetails = () => {
  return (
    <Box align="center" className="location_details">
      {/* <Title>Course Venue</Title>
      <MapIframe
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJp3Go9IQX2jERROpWZ4AgMFY&key=AIzaSyCivNJ_gLYd4zC1l2DV8P-fWal0W7hdYdo"
        height={360}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      /> */}
    </Box>
  )
}

export default ContactDetails
